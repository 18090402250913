// eslint-disable-next-line no-redeclare,no-unused-vars
function isHidden(settings, row, param) {
  if (!settings) return true;
  else if (settings.hidden === 1) return true;
  else if (
    isPlainObject(settings.hidden) &&
    checkWhere(row, settings.hidden, param)
  )
    return true;
  else if (
    settings.hidden &&
    isArray(settings.hidden) &&
    checkInArray(role, settings.hidden) &&
    !param.add
  )
    return true;
  else return false;
}
